import React, { Component } from 'react';

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const numberOfVideos = 174;

let lastVideoNumber;
let nextVideoNumber;
let playing = false;
let noise = true;

export default class Video extends Component {
  constructor(props) {
    super(props);
    this.vidRef = React.createRef();
  }

  state = {
    url: `${process.env.PUBLIC_URL}/whitenoise.webp`,
    autoplay: false,
    screenDisplay: '',
    btnClass: ''
  }

  componentDidMount() {
    this.pick()
  }

  pick() {
    if (noise) {
      this.setState({ url: `${process.env.PUBLIC_URL}/videos/whitenoise.mp4` })
      noise = false;
    } else {
      do {
        nextVideoNumber = randomIntFromInterval(1, numberOfVideos);
      } while (nextVideoNumber === lastVideoNumber)

      console.log('Started clip ' + nextVideoNumber + ' of ' + numberOfVideos)

      this.setState({ url: `${process.env.PUBLIC_URL}/videos/comercial/com-${nextVideoNumber}.mp4` })

      lastVideoNumber = nextVideoNumber;
      noise = true;
    }
  }

  playPause() {
    if (playing) {
      this.vidRef.current.pause();
      this.setState({
        screenDisplay: 'block',
        btnClass: ''
      })
      playing = false;
    } else {
      this.setState({
        autoplay: true,
        screenDisplay: 'none',
        btnClass: 'js-telegifion__button--hidden'
      })
      this.vidRef.current.play();
      playing = true;
    }
  }

  buttonClick() {
    if(!playing) {
      this.playPause()
    } else {
      this.pick()
    }
  }

  render() {
    const { url, autoplay, screenDisplay, btnClass } = this.state;
    return (
      <>
        <img style={{display: `${screenDisplay}`}} className="c-telegifion__screen" src={`${process.env.PUBLIC_URL}/screen.png`} alt="" />
        <div className="c-telegifion__video-container">
          <video ref={this.vidRef} className="c-telegifion__video" autoPlay={autoplay} playsInline={true} onEnded={()=> this.pick()} src={url} />
        </div>
        <button className={'c-telegifion__button ' + btnClass} onClick={() => this.buttonClick()}>
          <i className="c-telegifion__button-icon" />
        </button>
      </>
    )
  }
}