import React from 'react';
import './App.scss';

import Video from './Video';

function App() {
  return (
    <div className="c-telegifion">
      <div className="c-telegifion__wrapper">
        <img className="c-telegifion__tv" src={`${process.env.PUBLIC_URL}/tvscreen.png`} alt="" />
        <Video />
      </div>
    </div>
  );
}

export default App;